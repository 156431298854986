<template>
  <div class="pageContainer flexCloumn cbox sysgl">
    <div v-if="false" class="headbar">
      <div style="float: right;">
        <div>
          <el-input v-model="queryForm.keyword" size="small" placeholder="请输入关键字"
            style="width:200px;margin-right:10px"></el-input>
          <el-button size="small" type="primary" icon="el-icon-search" style="display:inline-block" @click="getList">查询
          </el-button>
          <el-button size="small" type="primary" icon="el-icon-plus" style="display:inline-block" @click="addForm">添加实验室
          </el-button>
          <el-button size="small" type="primary" plain style="display:inline-block" @click="showImportDialog = true">导入
          </el-button>


        </div>

      </div>
      <div style="padding-top: 14px;">
        <el-breadcrumb>
          <el-breadcrumb-item>实验室管理</el-breadcrumb-item>
          <el-breadcrumb-item>实验室设置</el-breadcrumb-item>

        </el-breadcrumb>
      </div>

    </div>

    <el-row class="flexBetween searchBar" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1">
        <el-input v-model="queryForm.keyword" size="small" placeholder="请输入关键字"
          style="width:200px;margin-right:10px"></el-input>
        <el-button size="small" type="primary" icon="el-icon-search" style="display:inline-block" @click="getList">查询
        </el-button>
        <el-button size="small" type="primary" icon="el-icon-plus" style="display:inline-block" @click="addForm">添加实验室
        </el-button>
        <el-button size="small" type="primary" plain style="display:inline-block" @click="showImportDialog = true">导入
        </el-button>
      </div>
    </el-row>
    <div class="dividerBar"></div>


    <div class="w_100 flex_1" style="overflow-y:auto;">
      <div class="flexStart" style="flex-wrap:wrap; padding: 20px 0 0 15px;width:100%;">

        <el-card v-for="(item, i) in DataList" :key="i" 
          style="width:calc( 20% - 10px );display: inline-block; cursor: pointer; vertical-align: top; margin: 0 10px 10px 0;">
          <div class="flexCenter w_100 overOneLine" :title="item.room_name" @click="view(item)"
            style="font-weight: bolder;text-align: center;">{{ item.room_name
            }}</div>
          <div class="flexCenter w_100" style="margin-top: 5px;">
            <span style="width:50%;height:2px;background-color: #ccc;"></span>
          </div>
          <div class="squareImg" style="" @click="view(item)">
            <div class="container">
              <div class="thumbnail w_100 h_100">
                <el-image v-if="item.files && item.files.length > 0" fit="cover" v-for="(img, index) in [item.files[0]]"
                  style="width: 100%; height: 100%;" :key="index"
                  :src="(img.fileUrl || img.src) + '&x-oss-process=image/resize,w_200,m_lfit'"
                  ></el-image>
                <el-image v-if="!item.files || item.files.length == 0" fit="cover" style="width: 100%; height: 100%;"
                  :src="require('../../../../public/img/sys_list_default.png')"></el-image>
                <div class="pos w_100 flexCenter">{{ item.address }}</div>
              </div>
            </div>
          </div>
          <div class="flexBetween w_100" style="margin-top:5px;">
            <div style="font-size: 14px; color:#606266;"><i class="el-icon-user"></i>{{ item.gly }} </div>
            <div style="font-size: 14px; color:#606266;"><i class="el-icon-mobile"></i>{{ item.lxdh }}</div>
          </div>

          <div class="flexCenter w_100" style="margin-top: 10px;">
            <el-tag class="pointer" size="mini" type="success" @click="view(item)" ><i
                class="el-icon-view" ></i>查看</el-tag>
            <el-tag class="ml10 pointer" size="mini" @click.stop="editItem(item)"><i
                class="el-icon-edit"></i>编辑</el-tag>

            <el-tag class="ml10 pointer" size="mini" type="danger" @click.stop="deleteItem(item)"><i
                class="el-icon-delete"></i>删除</el-tag>

          </div>
        </el-card>
      </div>

    </div>
    <el-table v-if="false" :data="DataList" height="100%" stripe border size="small"
      style="width:fit-content;width:100%;">
      <el-table-column type="index" label="编号" width="55" align="center"></el-table-column>


      <el-table-column prop="room_name" label="实验室名称" min-width="150" align="center">
        <template slot-scope="scope">
          <span style="color: #007AFF; cursor: pointer;" @click="view(scope.row)">{{ scope.row.room_name
            }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="实验室地址" min-width="185" show-overflow-tooltip
        align="center"></el-table-column>
      <el-table-column prop="room_desc" label="实验室简介" min-width="300" show-overflow-tooltip
        align="center"></el-table-column>
      <el-table-column prop="gly" label="管理员" min-width="100" align="center"></el-table-column>
      <el-table-column prop="lxdh" label="联系电话" min-width="200" align="center"></el-table-column>
      <el-table-column prop="kb" label="实验室课表" min-width="120" align="center">
        <template v-slot="scope">
          <el-tag type="small" @click="$router.push({ path: 'syssy', query: { roomId: scope.row.id } })">课表设置</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="zp" label="照片" min-width="150" align="center">
        <template v-slot="scope">
          <el-image v-for="(img, index) in scope.row.files" style="width: 35px; height: 35px;margin-left: 8px;"
            :key="index" :src="img.fileUrl || img.src" :preview-src-list="getImgs(scope.row.files)"></el-image>
        </template>
      </el-table-column>

      <!--                <el-table-column prop="room_desc" label="全景预览" width="120">
                            <template slot-scope="scope">

                                <el-tag size="mini" v-if="scope.row.vr_url" style="margin-left: 10px;cursor: pointer"
                                    @click="viewVR(scope.row)"><i class="el-icon-view"></i>预览</el-tag>

                            </template>
                        </el-table-column>-->


      <!--                <el-table-column prop="room_desc" label="实时监控" width="120">
                            <template slot-scope="scope">

                                <el-tag size="mini" v-if="scope.row.vr_url" style="margin-left: 10px;cursor: pointer"
                                    @click="viewJK(scope.row)"><i class="el-icon-view"></i>预览</el-tag>

                            </template>
                        </el-table-column>-->


      <el-table-column label="操作" width="200" fixed="right" align="center">
        <template slot-scope="scope">
          <el-tag class="pointer" size="mini" type="success" @click="view(scope.row)"><i
              class="el-icon-view"></i>查看</el-tag>
          <el-tag class="ml10 pointer" size="mini" @click="editItem(scope.row)"><i class="el-icon-edit"></i>编辑</el-tag>

          <el-tag class="ml10 pointer" size="mini" type="danger" @click="deleteItem(scope.row)"><i
              class="el-icon-delete"></i>删除</el-tag>

        </template>
      </el-table-column>

    </el-table>
    <!-- 
    <el-pagination class="cus_page" @size-change="handleSizeChange" @current-change="paginate"
      :current-page.sync="page.current_page" :page-sizes="[10, 50, 100]" :page-size="page.per_page"
      layout="total,sizes, prev, pager, next" :total="page.count" style="margin-top: 10px;">
    </el-pagination> -->





    <el-dialog :title="isView ? '查看实验室' : '编辑实验室'" :visible.sync="dialogVisible" custom-class="cus_dialog cus_dialogMax"
      :close-on-click-modal="false" width="900px">
      <el-form :model="EditItem" label-width="100px">
        <el-row v-if="!isView">
          <el-form-item label="实验室名称" prop="title">
            <el-input v-model="EditItem.room_name" placeholder="请输入实验室名称" :disabled="isView"></el-input>
          </el-form-item>

          <el-form-item label="实验室位置" prop="title">
            <el-input v-model="EditItem.address" placeholder="请输入课实验室位置" :disabled="isView"></el-input>
          </el-form-item>

          <el-form-item label="实验室介绍" prop="title">

            <el-input type="textarea" v-model="EditItem.room_desc" :autosize="{ minRows: 2 }" placeholder="请输入实验室介绍"
              :disabled="isView"></el-input>
          </el-form-item>

          <el-form-item label="实验室设备" prop="title">
            <el-input v-model="EditItem.sx_device" placeholder="请输入实验室设备" :disabled="isView"></el-input>
          </el-form-item>
          <el-form-item label="服务专业" prop="title">
            <el-input v-model="EditItem.profession" placeholder="请输入服务专业" :disabled="isView"></el-input>
          </el-form-item>

          <el-form-item label="管理员" prop="title">
            <el-input v-model="EditItem.gly" placeholder="请输入管理员" :disabled="isView"></el-input>
          </el-form-item>

          <el-form-item label="管理员电话" prop="title">
            <el-input v-model="EditItem.lxdh" placeholder="请输入管理员电话" :disabled="isView"></el-input>
          </el-form-item>

          <el-form-item v-if="!isView" label="附件" prop="title">
            <div style="display:inline-block; margin-right:10px;vertical-align: top; position: relative;"
              v-for="(img, idx) in EditItem.files" :key="idx">
              <el-image style="width: 100px; height: 100px;" :src="img.fileUrl || img.src"
                :preview-src-list="[img.fileUrl || img.src]" img="cover"></el-image>

              <div class="imgremove" @click="removeImg(idx)"><i class="el-icon-remove"></i></div>
            </div>
            <div style="display:inline-block;vertical-align: top;">
              <el-upload class="avatar-uploader" action="" :http-request="uploadOss" :on-success="uploadImage"
                :show-file-list="false" name="image" accept="image/png,image/jpeg,image/gif">
                <div class="imgaddbtn">
                  ＋
                </div>
              </el-upload>
            </div>
          </el-form-item>

          <el-form-item v-if="!isView" label="全景URL" prop="title">

            <el-input v-model="EditItem.vr_url" placeholder="请输入全景URL"></el-input>
          </el-form-item>
        </el-row>

        <el-row v-if="isView">
          <el-form-item label="实验室名称:" prop="title">{{ EditItem.room_name || '-' }}
          </el-form-item>

          <el-form-item label="实验室位置:" prop="title">{{ EditItem.address || '-' }}
          </el-form-item>

          <el-form-item label="实验室介绍:" prop="title">{{ EditItem.room_desc || '-' }}

          </el-form-item>

          <el-form-item label="实验室设备:" prop="title">{{ EditItem.sx_device || '-' }}
          </el-form-item>

          <el-form-item label="管理员:" prop="title">{{ EditItem.gly || '-' }}
          </el-form-item>

          <el-form-item label="管理员电话:" prop="title">{{ EditItem.lxdh || '-' }}
          </el-form-item>


        </el-row>


      </el-form>
      <div slot="footer" v-if="!isView" style="text-align:right">
        <el-button type="primary" icon="el-icon-check" style="display:inline-block" @click="saveForm">保存
        </el-button>

      </div>
    </el-dialog>


    <el-dialog title="实验室全景预览" :visible.sync="vrDialog" custom-class=" cus_dialog" style="left:1%;" width="90%" top="1%"
      :close-on-click-modal="false">

      <iframe :src="EditItem.vr_url" style="width:100%;height:85vh;border:0"></iframe>
    </el-dialog>

    <el-dialog title="导入" :visible.sync="showImportDialog" custom-class="cus_dialog" width="20%" style="height: 300px;"
      :close-on-click-modal="false">
      <el-button icon="el-icon-download" type="text">
        <a href="./downloadFiles/实验室导入模板.xlsx" target="_blank"
          style="text-decoration: none; color: inherit;font-size: 14px;">下载模板</a>
      </el-button>
      <el-upload style="text-align: center;margin-top: 10px;" action="" :http-request="uploadTemp"
        :on-success="uploadFiles" :show-file-list="false">
        <el-button size="small" icon="el-icon-upload2" type="primary">选择文件</el-button>
      </el-upload>
    </el-dialog>


    <div id="videomask"
      style="position:fixed;width:100%;height:100%; top:0; left:0; z-index: 9999; background-color: rgba(0,0,0,0.5); display: none; ">
      <div style="width:80vw;margin:0 auto; margin-top: 50px; position: relative;">
        <video id="myvideo" style="width:80vw" controls autoplay loop>
          <source :src="'./static/video/video' + videoId + '.mp4'" />
        </video>

        <div @click="closeVideo"
          style="position: absolute; z-index: 1; top:-16px;right:-16px; width:40px;height: 40px; background-color: #fff; border-radius: 50%; text-align: center; line-height: 40px;font-size: 20px; cursor: pointer;">
          <i class="el-icon-close"></i>
        </div>
      </div>


    </div>

    <el-dialog title="实验室详情" :visible.sync="showInfoDialog" custom-class="cus_dialog" width="80%" 
      :close-on-click-modal="false">
     <sysDetail :info="EditItem" v-if="showInfoDialog"></sysDetail>
    </el-dialog>


  </div>
</template>

<script>
import sysDetail from "./sysguanliDetail.vue"
export default {
  components: {sysDetail},
  data() {
    return {
      queryForm: {
        keyword: "",
        ktype: "room_name"
      },
      dialogVisible: false,
      showUserDialog: false,
      vrDialog: false,
      jkDialog: false,
      DataList: [],
      page: {
        current_page: 1,
        per_page: 100,
        count: 0,
        pageSizes: [10, 50, 100]
      },

      EditItem: {
        room_name: "",
        address: "",
        room_desc: "",
        vr_url: "",
        files: [],
        sx_device: "",
        gly: "",
        lxdh: ""
      },
      videoId: 0,
      showImportDialog: false,
      showInfoDialog:false,
      srcList: [],
      isView: false
    };
  },

  watch: {
    dialogVisible(n, o) {
      console.log(273, n)
      if (!n) {
        this.isView = false
      }
    }
  },
  mounted() {

    this.$http.post("/api/get_teacher_info", {}).then(res => {
      this.userinfo = res.data
      this.getList()
    })

  },
  methods: {

    getList() {
      this.$http.post("/api/t_sch_sysrooms_list", {
        page: this.page.current_page,
        pagesize: this.page.per_page,
        ktype: this.queryForm.ktype,
        keyword: this.queryForm.keyword

      }).then(res => {
        if (res.data.data && res.data.data.length) {
          res.data.data.map(d => {
            d.files = d.files ? JSON.parse(d.files) : []
          })
        }
        this.DataList = res.data.data

        this.page = res.data.page
      })
    },
    handleSizeChange(val) {
      this.page.current_page = 1
      this.page.pre_page = val
      this.getList();
    },
    paginate(val) {
      this.page.current_page = val
      this.getList();
    },
    addForm() {
      this.EditItem = {
        room_name: "",
        address: "",
        room_desc: "",
        vr_url: "",
        files: [],
        sx_device: "",
        gly: "",
        lxdh: ""
      }
      this.dialogVisible = true
    },
    view(e) {
      //this.isView = true
      //this.editItem(e)
      this.EditItem  = e
      this.showInfoDialog = true
      //this.$router.push({ path: 'sysDetail', query: { id: e.id, info: e } })
    },
    saveForm() {
      let formData = { ...this.EditItem }
      formData.files = JSON.stringify(formData.files)
      if (!formData.room_name.trim()) {
        this.$message.error('请输入实验室名称')
        return
      }
      if (formData.lxdh) {
        let regExp = /^1[3456789]\d{9}$/;
        if (!regExp.test(formData.lxdh)) {
          this.$message.error('联系电话格式不正确')
          return
        }
      }

      this.$http.post("/api/t_sch_sysrooms_edit", formData).then(res => {
        this.$message.success("保存成功")
        this.getList()
        this.dialogVisible = false
      })
    },
    editItem(e) {
      this.EditItem = { ...e };
      this.dialogVisible = true
    },
    deleteItem(e) {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/api/t_sch_sysrooms_delete', { id: e.id }).then(res => {
          this.$message.success('删除成功');
          this.getList()
        })
      })
    },
    getUser(e) {
      console.log('----------------')
      console.log(e)
      if (e && e.length > 0) {
        this.EditItem.teacher_id = e[0].id
        this.EditItem.username = e[0].username
      }
    },
    showDeptUserDialog() {
      //alert(111)
      this.showUserDialog = true
    },
    viewVR(row) {
      this.EditItem = row;
      this.vrDialog = true
    },
    viewJK(row) {
      this.videoId++
      if (this.videoId > 3) {
        this.videoId = 1
      }
      this.EditItem = row;
      this.jkDialog = true
      $("#videomask").show()
      $("#myvideo").attr("src", './static/video/video' + this.videoId + '.mp4')
      document.getElementById("myvideo").play()

    },
    closeVideo() {
      document.getElementById("myvideo").pause()
      $("#videomask").hide()

    },
    removeImg(idx) {
      this.EditItem.files.splice(idx, 1)
    },
    uploadImage(e) {
      this.EditItem.files.push(e)
    },
    getImgs(files) {
      return files.map(f => f.src || f.fileUrl)
    },
    uploadFiles(e) {
      let loadingInstance = this.$loading({
        lock: true,
        text: "正在导入，请稍等....",
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.3)',
      })
      this.$http
        .post('/api/sys_rooms_import', {
          url: e.src,
        })
        .then((res) => {
          this.$message.success("导入成功");
          if (res.data.errors && res.data.errors.length > 0) {
            let html = "";
            for (let msg of res.data.errors) {
              html +=
                "<div style='font-size:12px'>" + msg + "</div>";
            }
            this.$alert(html, "未导入数据", {
              dangerouslyUseHTMLString: true,
            });
          }
          this.getList();
          this.showImportDialog = false;
          loadingInstance.close()
        });
    },
  },
}
</script>

<style type="text/css" lang="less">
.sysgl {}
</style>
<style scoped="scoped" type="text/css" lang="less">
.sysgl {
  .squareImg {
    margin-top: 10px;
    width: 100%;
    position: relative;
  }

  .squareImg::before {
    content: '';
    display: block;
    padding-top: calc( 100% + 36px);
  }

  .squareImg .container {
    position: absolute;
    top:0;left:0;
    width: 100%;
    height: 100%;
    .thumbnail{
    position: relative;
    }
    .pos{
      position: absolute;
        bottom: 0;
        left: 0;
        height:36px;
        background-color: #000000a0;
        color:#fff;
    }
  }
}

.dttable td {
  text-align: center;
}

.imgaddbtn {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dotted #ccc;
  font-size: 30px;
  cursor: pointer;
}

.imgremove {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;

  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -5px;
  color: #ccc;
}

.imgremove:hover {
  color: orange;
}
</style>
