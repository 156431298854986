<template>
  <div class="pageContainer flexCloumn cbox sysglDetail">

    <div class="title flexCenter">{{ EditItem.room_name }}</div>
    <div class="dialog_right">
      <div class="flexStart"
        style="flex-wrap: wrap;width: 100%;margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">

        <div class="lineRow flexStretch" style="width:50%;">
          <div class="cell flexStart">管理员</div>
          <div class="cell flexStart flex_1">{{ EditItem.gly }}</div>
        </div>
        <div class="lineRow flexStretch" style="width:50%;">
          <div class="cell flexStart">管理员电话</div>
          <div class="cell flexStart flex_1">{{ EditItem.lxdh }}</div>
        </div>

        <div class="lineRow flexStretch" style="width:100%;">
          <div class="cell flexStart" style="min-height: 40px;height: unset;">
            实验室课程
          </div>
          <div class="courseContainer cell flexStart flex_1"
            style="min-height: 40px;height: unset;color:#606266;padding:3px;">
            <span class="mapBox" v-if="EditItem.courses && EditItem.courses.length > 0"
              v-for="(item, idx) in EditItem.courses">
              {{ item.course_name }}<span>/</span></span>
            <span v-if="!EditItem.courses || EditItem.courses.length == 0">-</span>
          </div>
        </div>
        <div class="lineRow flexStretch" style="width:100%;">
          <div class="cell flexStart" style="min-height: 40px;height: unset;">
            服务专业
          </div>
          <div class="cell flexStart flex_1" style="min-height: 40px;height: unset;color:#606266;padding:3px;">
            {{ EditItem.profession || '-' }}</div>
        </div>

        <div class="lineRow flexStretch" style="width:100%;">
          <div class="cell flexStart">实验室位置</div>
          <div class="cell flexStart flex_1">{{ EditItem.address }}</div>
        </div>

        <div class="lineRow flexStretch" style="width:100%;">
          <div class="cell flexStart" style="min-height: 40px;height: unset;">实验室设备</div>
          <div class="cell flexCloumn flex_1" style="height: unset;justify-content: center;min-height: 40px;">
            {{ EditItem.sx_device || '-' }}
          </div>
        </div>


        <div class="lineRow flexCloumn" style="width:100%;">
          <div class="cell flexStart" style="min-height: 40px;height: unset;width:100%;">
            实验室介绍
          </div>
          <div class="cell flexCloumn" style="height: unset;min-height: 40px;width:100%;">
            <div style="width:100%;">
              <div class=" flexCloumn">
                <div class=" flexStart" style="min-height: 40px;height: unset;color:#606266;padding:3px;">
                  {{ EditItem.room_desc || '-' }}</div>
              </div>
            </div>
          </div>
        </div>


        <div class="lineRow flexStretch" style="width:100%;">
          <div class="cell flexStart" style="min-height: 40px;height: unset;">附件</div>
          <div class="cell flexStart flex_1" style="height: unset;min-height: 40px;flex-wrap:wrap;padding-top:8px;">
            <div class="flexCenter" v-for="(img, idx) in EditItem.files"
              style="position:relative;height:200px;width:200px;border:1px dashed #c0ccda;border-radius:6px;margin-right:8px;margin-bottom:8px;">
              <el-image style="max-height:100%;max-width:100%;" :src="img.fileUrl || img.src"
                :preview-src-list="[img.fileUrl || img.src]" img="cover" :z-index="3000"></el-image>
            </div>
            <div v-if="!EditItem.files || EditItem.files.length == 0" class="" style="width:100%;">-
            </div>
          </div>
        </div>
        <div class="lineRow flexStretch" style="width:100%;">
          <div class="cell flexStart" style="min-height: 40px;height: unset;">全景URL</div>
          <div class="cell flexCloumn flex_1" style="height: unset;justify-content: center;min-height: 40px;">
            {{ EditItem.vr_url || '-' }}
          </div>
        </div>









      </div>
    </div>


    <el-form v-if="false" :model="EditItem" label-width="100px">
      <el-row v-if="!isView">
        <el-form-item label="实验室名称" prop="title">
          <el-input v-model="EditItem.room_name" placeholder="请输入实验室名称" :disabled="isView"></el-input>
        </el-form-item>

        <el-form-item label="实验室位置" prop="title">
          <el-input v-model="EditItem.address" placeholder="请输入课实验室位置" :disabled="isView"></el-input>
        </el-form-item>

        <el-form-item label="实验室介绍" prop="title">

          <el-input type="textarea" v-model="EditItem.room_desc" :autosize="{ minRows: 2 }" placeholder="请输入实验室介绍"
            :disabled="isView"></el-input>
        </el-form-item>

        <el-form-item label="实验室设备" prop="title">
          <el-input v-model="EditItem.sx_device" placeholder="请输入实验室设备" :disabled="isView"></el-input>
        </el-form-item>

        <el-form-item label="管理员" prop="title">
          <el-input v-model="EditItem.gly" placeholder="请输入管理员" :disabled="isView"></el-input>
        </el-form-item>

        <el-form-item label="管理员电话" prop="title">
          <el-input v-model="EditItem.lxdh" placeholder="请输入管理员电话" :disabled="isView"></el-input>
        </el-form-item>

        <el-form-item v-if="!isView" label="附件" prop="title">
          <div style="display:inline-block; margin-right:10px;vertical-align: top; position: relative;"
            v-for="(img, idx) in EditItem.files" :key="idx">
            <el-image style="width: 100px; height: 100px;" :src="img.fileUrl || img.src"
              :preview-src-list="[img.fileUrl || img.src]" img="cover"></el-image>

            <div class="imgremove" @click="removeImg(idx)"><i class="el-icon-remove"></i></div>
          </div>
          <div style="display:inline-block;vertical-align: top;">

          </div>
        </el-form-item>

        <el-form-item v-if="!isView" label="全景URL" prop="title">

          <el-input v-model="EditItem.vr_url" placeholder="请输入全景URL"></el-input>
        </el-form-item>
      </el-row>

      <el-row v-if="isView">
        <el-form-item label="实验室名称:" prop="title">{{ EditItem.room_name || '-' }}
        </el-form-item>

        <el-form-item label="实验室位置:" prop="title">{{ EditItem.address || '-' }}
        </el-form-item>

        <el-form-item label="实验室介绍:" prop="title">{{ EditItem.room_desc || '-' }}

        </el-form-item>

        <el-form-item label="实验室设备:" prop="title">{{ EditItem.sx_device || '-' }}
        </el-form-item>

        <el-form-item label="管理员:" prop="title">{{ EditItem.gly || '-' }}
        </el-form-item>

        <el-form-item label="管理员电话:" prop="title">{{ EditItem.lxdh || '-' }}
        </el-form-item>


      </el-row>


    </el-form>



    <el-dialog title="实验室全景预览" :visible.sync="vrDialog" custom-class=" cus_dialog" style="left:1%;" width="90%" top="1%"
      :close-on-click-modal="false">

      <iframe :src="EditItem.vr_url" style="width:100%;height:85vh;border:0"></iframe>
    </el-dialog>



    <div id="videomask"
      style="position:fixed;width:100%;height:100%; top:0; left:0; z-index: 9999; background-color: rgba(0,0,0,0.5); display: none; ">
      <div style="width:80vw;margin:0 auto; margin-top: 50px; position: relative;">
        <video id="myvideo" style="width:80vw" controls autoplay loop>
          <source :src="'./static/video/video' + videoId + '.mp4'" />
        </video>

        <div @click="closeVideo"
          style="position: absolute; z-index: 1; top:-16px;right:-16px; width:40px;height: 40px; background-color: #fff; border-radius: 50%; text-align: center; line-height: 40px;font-size: 20px; cursor: pointer;">
          <i class="el-icon-close"></i>
        </div>
      </div>


    </div>


  </div>
</template>

<script>

export default {
  name:"sysdetail",
  components: {},
  props:["info"],
  data() {
    return {
      queryForm: {
        keyword: "",
        ktype: "room_name"
      },
      dialogVisible: false,
      showUserDialog: false,
      vrDialog: false,
      jkDialog: false,
      DataList: [],
      page: {
        current_page: 1,
        per_page: 100,
        count: 0,
        pageSizes: [10, 50, 100]
      },

      EditItem: {
        room_name: "",
        address: "",
        room_desc: "",
        vr_url: "",
        files: [],
        sx_device: "",
        gly: "",
        lxdh: ""
      },
      videoId: 0,
      showImportDialog: false,
      srcList: [],
      isView: false
    };
  },

  watch: {
    dialogVisible(n, o) {
   
      if (!n) {
        this.isView = false
      }
    }
  },
  created() {
   
    if (this.$route.query.info && this.$route.query.info.id) {
      this.EditItem = this.$route.query.info;
    }else{
      if(this.info){
        this.EditItem=this.info
      }
    }
  },
  mounted() {
  
    this.$http.post("/api/get_teacher_info", {}).then(res => {
      this.userinfo = res.data
      // this.getList()
    })

  },
  methods: {


    view(e) {
      this.isView = true
      this.editItem(e)
    },

    editItem(e) {
      this.EditItem = { ...e };
      this.dialogVisible = true
    },


    viewVR(row) {
      this.EditItem = row;
      this.vrDialog = true
    },
    viewJK(row) {
      this.videoId++
      if (this.videoId > 3) {
        this.videoId = 1
      }
      this.EditItem = row;
      this.jkDialog = true
      $("#videomask").show()
      $("#myvideo").attr("src", './static/video/video' + this.videoId + '.mp4')
      document.getElementById("myvideo").play()

    },
    closeVideo() {
      document.getElementById("myvideo").pause()
      $("#videomask").hide()

    },

  },
}
</script>

<style type="text/css" lang="less">
.sysgl {}
</style>
<style scoped="scoped" type="text/css" lang="less">
.sysglDetail {
  align-items: center;

  .title {
    color: #2469f3;
    font-size: 26px;
    font-weight: 700;
    height: 60px;
  }

  .dialog_left,
  .dialog_right {
    height: calc(100% - 60px);
    overflow-y: auto;
    width: 90%;

    .lineRow {
      width: 50%;

      .cell {
        color: #4f6398;
        border-top: 1px solid #d9e4f6;
        border-left: 1px solid #d9e4f6;
        padding: 0 8px;
        height: 42px;
        font-size: 18px;

        div {
          color: #4f6398;
        }
      }

      .cell:first-of-type {
        background-color: #f3f6fb;
        width: 150px;
        font-weight: 700;
      }

      .courseContainer {
        .mapBox:last-of-type {
          span {
            display: none;
          }
        }
      }
    }
  }
}

.dttable td {
  text-align: center;
}

.imgaddbtn {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dotted #ccc;
  font-size: 30px;
  cursor: pointer;
}

.imgremove {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;

  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -5px;
  color: #ccc;
}

.imgremove:hover {
  color: orange;
}
</style>
